<template>
  <div class="wrap">
    <div class="container">
      <div class="row">
        <div class="col" style="overflow: hidden">
          <div class="swiper mySwiper position-relative">
            <div class="swiper-wrapper text-center">
              <div class="swiper-slide d-flex align-items-center h-100">
                <div
                  class="slide-wrap d-flex"
                  v-for="item in developData"
                  :key="item"
                  style="float: left"
                >
                  <div class="title-box d-flex align-items-center">
                    <h5>
                      {{ item.title }}
                      <p>（{{ item.years }}）</p>
                    </h5>
                  </div>
                  <div class="detail-box d-flex">
                    <div
                      class="item"
                      v-for="(it, index) in item._sub_"
                      :key="it"
                    >
                      <div class="top">
                        <!-- <div class="year" v-if="(index + 1) % 2 !== 0">

                        </div> -->

                        <div class="info demo" v-if="(index + 1) % 2 !== 0">
                          <div class="year" v-if="(index + 1) % 2 !== 0">
                            {{ it.title }}
                          </div>
                          {{ it.desc }}
                        </div>

                        <img
                          :src="it.image"
                          v-if="(index + 1) % 2 === 0 && it.image"
                        />
                      </div>
                      <div class="bottom">
                        <div class="info" v-if="(index + 1) % 2 === 0">
                          <div class="year" v-if="(index + 1) % 2 === 0">
                            {{ it.title }}
                          </div>
                          {{ it.desc }}
                        </div>
                        <img
                          :src="it.image"
                          v-if="(index + 1) % 2 !== 0 && it.image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, nextTick } from 'vue'
import { getDevelop } from '../../../server/intoHuadu'
import Swiper from 'swiper'

const sw = ref(null)
const developData = ref([])

onMounted(async () => {
  await fetch()
  await nextTick()
  initSwiper()
})

onUnmounted(() => {
  sw.value = null
})

const fetch = async () => {
  developData.value = await getDevelop()
}
const initSwiper = () => {
  sw.value = new Swiper('.mySwiper', {
    direction: 'horizontal',
    slidesPerView: 'auto',
    freeMode: true,
    scrollbar: {
      el: '.swiper-scrollbar'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    mousewheel: true
  })
}
</script>
<style lang="stylus" scoped>
.wrap
  background url('../../../assets/30.png') repeat-x
  background-position bottom center
  background-size auto
  width 100%
.mySwiper
  height 780px
  // margin-top 100px
.swiper-slide
  width auto
.slide-wrap
  cursor pointer
  .title-box
    & > h5
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #10366B;
      line-height: 35px;
    p
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666
      line-height: 35px;
  .detail-box
    .item
      width 178px
      .top
        margin-bottom 25px
        height 224px
        width 100%
        border-bottom 3px solid #218EC9
        position relative
        padding 0 24px
        &::after
          content ''
          position absolute
          left 50%
          transform translateX(-50%)
          bottom -10px
          width 17px
          height 17px
          border 3px solid #218EC9
          border-radius 100%
          background #fff
        & > img
          position absolute
          left 50%
          bottom 15px
          transform translateX(-50%)
          object-fit cover
        .year
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #218EC9;
          line-height: 35px;
          text-align left
        .info
          // width: 100%
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 25px;
          text-align left
          position absolute
          bottom 15px
          left 24px
          right 24px
      .bottom
        width 100%
        height 224px
        position relative
        .year
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #218EC9;
          line-height: 35px;
          text-align left
        .info
          width: 100%
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 25px;
          text-align left
          position absolute
          top 15px
      img
        display block
        margin 0 auto
        width: 130px;
        height: 115px;
        background: #C4C4C4;
        border-radius: 5px;
        object-fit cover
</style>
